<template>
    <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="color" id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M0 22.1055C0 21.6205 0.185823 21.1354 0.55874 20.7653L9.39167 12L0.55874 3.23472C-0.185823 2.49449 -0.185823 1.29446 0.55874 0.554225C1.30458 -0.184742 2.51369 -0.184742 3.25953 0.554225L13.4416 10.6598C14.1861 11.4 14.1861 12.6 13.4416 13.3402L3.25953 23.4458C2.51369 24.1847 1.30458 24.1847 0.55874 23.4458C0.185823 23.0757 0 22.5906 0 22.1055Z" :fill="color"/>
</svg>


</template>

<script>
export default {
  name: 'Corner1',
  props: {
    color: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
</style>